import mobileProdWave from "../../../images/mobile-prod-wave.png";
import desktopProdWave from "../../../images/desktop-prod-wave.png";
import selectArrow from "../../../images/select-arrow.png";
import selectArrowWhite from "../../../images/select-arrow-white.png";
import buttonArrow from "../../../images/button-arrow-white.png";
import wishlist from "../../../images/basket-regular.png";
import wishlistFilled from "../../../images/basket-filled.png";
import blockArrow from "../../../images/product-arrow.jpg";
import sliderArrow from "../../../images/button-arrow-pink.png";
import i360 from "../../../images/360-cam.png";
import slider from "react-slick/lib/slider";

export default (theme) => ({
  productIntro: {
    position: "relative",
    zIndex: 1,
    backgroundColor: "white",
    marginBottom: 100,
    boxShadow: "-36px 0 0 white, 36px 0 0 white",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginBottom: 120,
    },
    "& .SocialShare": {
      position: "absolute",
      top: 12,
      right: 24,
      zIndex: 10,
      paddingBottom: 0,
      [theme.breakpoints.up("md")]: {
        top: 32,
      },
    },
    "& .product-data": {
      position: "relative",
      width: "calc(100% + 36px)",
      margin: "0 -18px",
      padding: "44px 18px 28%",
      [theme.breakpoints.up("md")]: {
        flex: "0 0 50%",
        width: "50%",
        maxWidth: "50%",
        padding: "100px 24px 0 0",
        margin: 0,
      },
      "& .code": {
        maxWidth: "100%",
        backgroundColor: "rgba(255,255,255,0.1)",
        borderRadius: 8,
        padding: "12px 20px",
        justifyContent: "space-between",
        marginBottom: 12,
        textAlign: "left",
        width: "100%",
        outline: "none",
        fontWeight: "normal",
        marginTop: 0,
        border: "2px solid transparent",
        fontSize: 16,
        letterSpacing: "0.008em",
        lineHeight: 1.5,
        margin: 0,
        marginBottom: 24,
        border: "2px solid white",
        maxWidth: 500,
        "& span": {
          fontSize: 10,
          letterSpacing: "0.152em",
          display: "block",
          lineHeight: 2,
          textTransform: "uppercase",
          margin: 0,
          color: theme.palette.primary.contrastText,
          opacity: 0.75,
        },
      },
      "& .texture": {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: -1,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundBlendMode: "multiply",
        [theme.breakpoints.up("md")]: {
          width: "50vw",
          left: "auto",
        },
        "&::before": {
          position: "absolute",
          content: '""',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom left",
          backgroundSize: "contain",
          backgroundImage: "url(" + mobileProdWave + ")",
          boxShadow: "500px -500px 0px 500px white",
          [theme.breakpoints.up("md")]: {
            backgroundPosition: "bottom right",
            backgroundSize: "cover",
            backgroundImage: "url(" + desktopProdWave + ")",
          },
        },
        "& .brand-logo": {
          maxWidth: "150px !important",
          marginTop: 14,
          [theme.breakpoints.up("md")]: {
            marginTop: 44,
          },
        },
      },
      "& h1": {
        color: theme.palette.primary.contrastText,
        fontSize: 20,
        lineHeight: 1.4,
        letterSpacing: "0.008em",
        margin: "30px 0 16px",
        [theme.breakpoints.up("md")]: {
          fontSize: 32,
        },
      },
      "& p": {
        color: theme.palette.primary.contrastText,
        fontSize: 14,
        lineHeight: 1.42,
        letterSpacing: "0.032em",
        margin: "0 0 32px",
        [theme.breakpoints.up("md")]: {
          maxWidth: "88%",
        },
      },
    },
    "& .product-accreditations": {
      position: "absolute",
      zIndex: 10,
      right: 0,
      [theme.breakpoints.up("sm")]: {
        marginTop: "-11%",
      },
      [theme.breakpoints.up("md")]: {
        marginTop: 56,
      },
      "& .accred-logo": {
        width: 66,
        height: 66,
        backgroundColor: theme.palette.primary.main + "1A",
        borderRadius: "50%",
        marginBottom: 12,
        [theme.breakpoints.up("md")]: {
          width: 88,
          height: 88,
          marginBottom: 24,
        },
      },
    },
    "& .product-gallery": {
      [theme.breakpoints.up("sm")]: {
        margin: "-11% auto 70px",
        maxWidth: 540,
      },
      [theme.breakpoints.up("md")]: {
        flex: "0 0 calc(50% - 88px)",
        width: "calc(50% - 88px)",
        maxWidth: "calc(50% - 88px)",
        marginLeft: 0,
        marginRight: 0,
        padding: 0,
        marginTop: 0,
        marginBottom: 0,
      },
      "& .image-slider": {
        "& .slick-slide": {
          [theme.breakpoints.up("md")]: {
            padding: 32,
          },
        },
      },
      "& .gatsby-image-wrapper": {
        maxWidth: "75%",
        [theme.breakpoints.up("md")]: {
          maxWidth: "100%",
        },
      },
      "& .slider-nav": {
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "block",
        },

        "& .slick-slide": {
          "&.slick-cloned": {
            display: "none !important",
          },

          "& > div": {
            position: "relative",
            width: 42,
            aspectRatio: 1,
            display: "inline-grid",
            placeItems: "center",

            [theme.breakpoints.up("md")]: {
              width: 92,
            },

            "& .i360": {
              width: 42,
              height: 42,
              backgroundImage: `url(${i360})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-21px, -21px)",
              fontSize: 0,
            },
          },
        },
        "& .slick-arrow": {
          height: "100%",
          width: 60,
          zIndex: "10",
          background:
            "linear-gradient(-90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)",
          "&::before": {
            color: "transparent",
            width: 40,
            height: 40,
            backgroundImage: "url(" + sliderArrow + ")",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          },
          "&.slick-prev": {
            transform: "translate(0, -50%) rotate(180deg)",
            left: -10,
          },
          "&.slick-next": {
            right: -10,
          },
        },
      },
      "& ul.slick-dots": {
        [theme.breakpoints.up("md")]: {
          display: "none",
        },
        "& li": {
          margin: 0,
          "&.slick-active": {
            "& button": {
              "&::before": {
                color: "white",
                transform: "scale(2)",
                textShadow: "0 0 1px black",
              },
            },
          },
        },
      },
      "& .slick-nav": {
        flex: "0 0 84px",
        marginLeft: 24,
        padding: "30px 0",
        maxHeight: 292,
        maxWidth: 84,
        [theme.breakpoints.up("md")]: {
          flex: "0 0 134px",
          maxWidth: 158,
          maxHeight: "var(--slick-slider-maxheight)",
        },

        "& .slick-slide": {
          // width: "58px !important",
          // height: "58px !important",
          margin: "0 12px",
          border: "2px solid transparent",
          padding: 8,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 11,
          fontSize: 0,
          cursor: "pointer",
          maxWidth: "fit-content",

          [theme.breakpoints.up("md")]: {
            // width: "108px !important",
            // height: "108px !important",
          },

          "& > div": {
            position: "relative",
            width: 42,
            aspectRatio: 1,
            display: "inline-grid",
            placeItems: "center",

            [theme.breakpoints.up("md")]: {
              width: 92,
            },

            "& .i360": {
              width: 42,
              height: 42,
              backgroundImage: `url(${i360})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-21px, -21px)",
            },
          },
          "&.slick-current": {
            border: "2px solid #424496",
          },
        },
        "& .slick-arrow": {
          width: "100%",
          left: 0,
          right: 0,
          height: 44,
          zIndex: 10,
          transform: "none",
          transformOrigin: "50% 50%",
          backgroundImage:
            "linear-gradient(0deg, rgba(255,255,255,1) 66.666%, rgba(255,255,255,0) 100%)",
          "&::before": {
            backgroundImage: `url(${sliderArrow})`,
            content: "''",
            width: 44,
            height: 44,
            display: "block",
            backgroundPosition: "right center",
            backgroundRepeat: "no-repeat",
            transform: "rotate(90deg)",
            margin: "0 auto !important",
          },
          "&.slick-prev": {
            top: 0,
            bottom: "auto",
            transform: "rotate(180deg)",
          },
          "&.slick-next": {
            bottom: 0,
            top: "auto",
          },
        },
      },
    },
    "& .mobile-prod-variants": {
      backgroundColor: theme.palette.primary.main + "1A",
      position: "relative",
      width: "calc(100% + 36px)",
      margin: "0 -18px",
      padding: "32px 18px",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
      "& .variant-details": {
        "& dl": {
          margin: 0,
          width: "50%",
          float: "left",
          "& dt": {
            fontSize: 8,
            letterSpacing: "0.152em",
            color: theme.palette.primary.main,
            lineHeight: 2,
            textTransform: "uppercase",
            margin: 0,
            opacity: 0.75,
          },
          "& dd": {
            fontSize: 16,
            letterSpacing: "0.008em",
            color: theme.palette.primary.main,
            lineHeight: 1.5,
            margin: 0,
            marginBottom: 24,
          },
        },
        "&::after": {
          clear: "both",
          display: "table",
          content: '""',
        },
      },
      "& .variant-change": {
        "& span": {
          fontSize: 8,
          letterSpacing: "0.152em",
          color: theme.palette.primary.main,
          lineHeight: 2,
          textTransform: "uppercase",
          margin: 0,
          opacity: 0.8,
        },
        "& select": {
          "-webkit-appearance": "none",
          "-moz-appearance": "none",
          fontSize: 16,
          fontWeight: 500,
          letterSpacing: "0.016em",
          lineHeight: 1.5,
          color: theme.palette.primary.main + "4D",
          fontFamily: theme.bodyFont,
          backgroundColor: "transparent",
          border: "none",
          borderBottom: "2px solid " + theme.palette.primary.main,
          outline: "none",
          padding: "5px 32px 10px 0",
          maxWidth: "100%",
          width: "100%",
          backgroundImage: "url(" + selectArrow + ")",
          backgroundPosition: "right top 8px",
          backgroundRepeat: "no-repeat",
          marginBottom: 24,
          "& option": {
            color: theme.palette.primary.main,
          },
        },
      },
      "& button": {
        borderRadius: 26,
        fontFamily: theme.bodyFont,
        fontSize: 18,
        letterSpacing: "0.008em",
        lineHeight: 2,
        fontWeight: 700,
        border: "none",
        padding: "10px 24px",
        marginTop: 24,
        position: "relative",
        display: "table",
        "&::after": {
          content: '""',
          display: "inline-block",
          marginLeft: 12,
          width: 22,
          height: 20,
          verticalAlign: "text-top",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        },
        "&.wishlist": {
          backgroundColor: theme.palette.primary.contrastText,
          color: theme.palette.primary.main,
          boxShadow: "0 24px 26px rgba(0,0,0,0.16)",
          "&::after": {
            backgroundImage: "url(" + wishlist + ")",
          },
          "&.added": {
            "&::after": {
              backgroundImage: "url(" + wishlistFilled + ")",
            },
          },
          "& span": {
            position: "absolute",
            color: "white",
            fontSize: 8,
            lineHeight: 1,
            padding: 2,
            backgroundColor: "#E2004F",
            borderRadius: "50%",
            border: "1px solid white",
            width: 14,
            height: 14,
            textAlign: "center",
            top: 14,
            right: 22,
          },
        },
        "&.order": {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
          "&::after": {
            backgroundImage: "url(" + buttonArrow + ")",
          },
        },
      },
    },
    "& .desktop-prod-variants": {
      // display: 'none',
      position: "relative",
      [theme.breakpoints.up("md")]: {
        display: "block",
        maxWidth: 500,
      },
      "& .variant-wrap": {
        marginBottom: 12,
        position: "relative",
        "& .variant-details": {
          maxWidth: "100%",
          backgroundColor: "rgba(255,255,255,0.1)",
          borderRadius: 8,
          padding: "12px 20px",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: "flex-start",
          textAlign: "left",
          width: "100%",
          outline: "none",
          fontWeight: "normal",
          marginTop: 0,
          border: "2px solid transparent",
          "&.selected": {
            border: "2px solid white",
            padding: "12px 56px 12px 20px",
            backgroundImage: "url(" + blockArrow + ")",
            backgroundPosition: "center right",
            backgroundRepeat: "no-repeat",
          },
          "&:not(.has-4)": {
            "& dl": {
              "&:nth-of-type(1)": {
                flex: "0 0 40%",
                maxWidth: "40%",
              },
            },
          },
          "& dl": {
            margin: 0,
            flex: "0 0 20%",
            maxWidth: "20%",
            paddingRight: 10,
            "& dt": {
              fontSize: 10,
              letterSpacing: "0.152em",
              color: theme.palette.primary.contrastText,
              lineHeight: 1.2,
              textTransform: "uppercase",
              margin: 0,
              opacity: 0.75,
              height: 24,
            },
            "& dd": {
              fontSize: 16,
              letterSpacing: "0.008em",
              color: theme.palette.primary.contrastText,
              lineHeight: 1.5,
              margin: 0,
            },
          },
          "&::after": {
            display: "none",
          },
        },
        "& .variant-select": {
          height: 0,
          overflow: "hidden",
          opacity: 0,
          transition: ".3s ease-in-out opacity",
          "&[open]": {
            height: "auto",
            position: "absolute",
            top: "100%",
            right: 10,
            zIndex: 12,
            opacity: 1,
            left: 10,
            backgroundColor: "white",
            boxShadow: "0 4px 16px rgba(0,0,0,0.16)",
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            maxHeight: 300,
            overflowY: "scroll",
          },
          "& .variant-details": {
            borderBottom: "1px solid " + theme.palette.primary.main + "14",
            marginBottom: 0,
            transition: "background-color .3s ease-in-out",
            width: "100%",
            maxWidth: "100%",
            borderRadius: 0,
            "& dl": {
              "& dd, & dt": {
                color: theme.palette.primary.main,
              },
            },
            "&:hover, &:focus": {
              backgroundColor: theme.palette.primary.main + "14",
            },
          },
        },
      },
      "& .variant-change": {
        maxWidth: "55%",
        "& span": {
          fontSize: 8,
          letterSpacing: "0.152em",
          color: theme.palette.primary.main,
          lineHeight: 2,
          textTransform: "uppercase",
          margin: 0,
          opacity: 0.8,
        },
        "& select": {
          "-webkit-appearance": "none",
          "-moz-appearance": "none",
          fontSize: 16,
          fontWeight: 500,
          letterSpacing: "0.016em",
          lineHeight: 1.5,
          color: theme.palette.primary.contrastText + "4D",
          fontFamily: theme.bodyFont,
          backgroundColor: "transparent",
          border: "none",
          borderBottom: "2px solid " + theme.palette.primary.contrastText,
          outline: "none",
          padding: "5px 32px 10px 0",
          maxWidth: "100%",
          width: "100%",
          backgroundImage: "url(" + selectArrowWhite + ")",
          backgroundPosition: "right top 8px",
          backgroundRepeat: "no-repeat",
          marginBottom: 24,
          "& option": {
            color: theme.palette.primary.main,
          },
        },
      },
      "& button, & a": {
        borderRadius: 26,
        fontFamily: theme.bodyFont,
        fontSize: 18,
        letterSpacing: "0.008em",
        lineHeight: 2,
        fontWeight: 700,
        border: "none",
        padding: "10px 24px",
        margin: "24px 24px 0 0",
        position: "relative",
        display: "inline-block",
        textDecoration: "none",
        "&::after": {
          content: '""',
          display: "inline-block",
          marginLeft: 12,
          width: 22,
          height: 20,
          verticalAlign: "text-top",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        },
        "&.wishlist": {
          backgroundColor: theme.palette.primary.contrastText,
          color: theme.palette.primary.main,
          boxShadow: "0 24px 26px rgba(0,0,0,0.16)",
          "&::after": {
            backgroundImage: "url(" + wishlist + ")",
          },
          "&.added": {
            "&::after": {
              backgroundImage: "url(" + wishlistFilled + ")",
            },
          },
          "& span": {
            position: "absolute",
            color: "white",
            fontSize: 8,
            lineHeight: 1,
            padding: 2,
            backgroundColor: "#E2004F",
            borderRadius: "50%",
            border: "1px solid white",
            width: 14,
            height: 14,
            textAlign: "center",
            top: 14,
            right: 22,
          },
        },
        "&.order": {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
          "&::after": {
            backgroundImage: "url(" + buttonArrow + ")",
          },
        },
      },
    },
  },
  productIntroTheme: {
    ...theme.productIntroTheme,
  },
});
