import React from "react";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import { Link } from "gatsby";

import {
  toggleWishlistItem,
  openOverlay,
} from "../../../app/actions";

const mapStateToProps = (state) => ({
  wishlist: state.WishlistSettings,
});

const mapDispatchToProps = (dispatch) => ({
  toggleWishlistItem: (item) => dispatch(toggleWishlistItem(item)),
  openOverlay: (settings) => dispatch(openOverlay(settings)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

class ProductVariants extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      index: 0,
      code: props.variations ? props.variations[0].variantCode : props.code,
    };
  }

  componentDidMount() {
    if (!this.props.preselect || !this.props.variations) return;

    const preselected = this.props.variations
      .map((variation, index) => ({ code: variation.variantCode, index }))
      .find((variation) => variation.code.includes(this.props.preselect));

    if (preselected) {
      const { code, index } = preselected;
      this.setState({
        index,
        code,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.wishlist !== this.props.wishlist) {
      Cookies.set("wishlist", this.props.wishlist);
    }
  }

  wishlistHandler() {
    const { toggleWishlistItem } = this.props;
    const { code } = this.state;
    toggleWishlistItem(code);
  }

  toggleVariants() {
    this.setState({
      open: !this.state.open,
    });
  }

  selectVariant(index, code) {
    this.setState(
      {
        index,
        code,
      },
      () => {
        this.toggleVariants();
      }
    );
  }

  openOverlay() {
    const settings = { buyOnline: this.props.buyOnline, buyOffline: this.props.buyOffline };
    this.props.openOverlay(settings);
  }

  render() {
    const { productId, wishlist } = this.props;
    const { index } = this.state;

    let ctaButton = null;
    if (this.props.buyOffline || this.props.buyOnline) {
      ctaButton = <button className="order" onClick={() => this.openOverlay()}> Order now </button>
    } else {
      ctaButton = <Link className="order" to="/about-us/contact-us/">Contact us</Link>
    }
    return (
      <>
        <div className="variant-wrap">
          {this.props.variations !== null && (
            <button
              className={`variant-details selected ${
                this.props.variations[index].option4Name != null
                  ? `has-4`
                  : `${
                      this.props.variations[index].option3Name != null
                        ? `has-3`
                        : `${
                            this.props.variations[index].option2Name != null
                              ? `has-2`
                              : ``
                          }`
                    }`
              }`}
              onClick={(e) => this.toggleVariants()}
            >
              {this.props.variations[index].option1Name != null && (
                <dl>
                  <dt>{this.props.variations[index].option1Name}</dt>
                  <dd>{this.props.variations[index].option1Value}</dd>
                </dl>
              )}
              {this.props.variations[index].option2Name != null && (
                <dl>
                  <dt>{this.props.variations[index].option2Name}</dt>
                  <dd>{this.props.variations[index].option2Value}</dd>
                </dl>
              )}
              {this.props.variations[index].option3Name != null && (
                <dl>
                  <dt>{this.props.variations[index].option3Name}</dt>
                  <dd>{this.props.variations[index].option3Value}</dd>
                </dl>
              )}
              {this.props.variations[index].option4Name != null && (
                <dl>
                  <dt>{this.props.variations[index].option4Name}</dt>
                  <dd>{this.props.variations[index].option4Value}</dd>
                </dl>
              )}
              <dl>
                <dt>Code</dt>
                <dd>{this.props.variations[index].variantCode}</dd>
              </dl>
            </button>
          )}
          <div className="variant-select" open={this.state.open}>
            {this.props.variations !== null &&
              this.props.variations.map((variant, index) => {
                return (
                  <button
                    className={`variant-details ${
                      variant.option4Name != null
                        ? `has-4`
                        : `${
                            variant.option3Name != null
                              ? `has-3`
                              : `${variant.option2Name != null ? `has-2` : ``}`
                          }`
                    }`}
                    value={variant.variantCode}
                    key={variant.variantCode}
                    onClick={() =>
                      this.selectVariant(index, variant.variantCode)
                    }
                  >
                    {variant.option1Name != null && (
                      <dl>
                        <dt>{variant.option1Name}</dt>
                        <dd>{variant.option1Value}</dd>
                      </dl>
                    )}
                    {variant.option2Name != null && (
                      <dl>
                        <dt>{variant.option2Name}</dt>
                        <dd>{variant.option2Value}</dd>
                      </dl>
                    )}
                    {variant.option3Name != null && (
                      <dl>
                        <dt>{variant.option3Name}</dt>
                        <dd>{variant.option3Value}</dd>
                      </dl>
                    )}
                    {variant.option4Name != null && (
                      <dl>
                        <dt>{variant.option4Name}</dt>
                        <dd>{variant.option4Value}</dd>
                      </dl>
                    )}
                    <dl>
                      <dt>Code</dt>
                      <dd>{variant.variantCode}</dd>
                    </dl>
                  </button>
                );
              })}
          </div>
        </div>
        <button
          className={
            wishlist.includes(this.state.code) ? "wishlist added" : "wishlist"
          }
          onClick={() => this.wishlistHandler()}
        >
          Add to Quote Builder
        </button>
        {ctaButton}
      </>
    );
  }
}

export default connector(ProductVariants);
