import arrowWhite from '../../../images/button-arrow-white.png';
import arrowBlue from '../../../images/button-arrow.png';
import close from '../../../images/close-icon.png';

export default theme => ({
    productFix: {
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        '&:before, &:after': { 
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            bottom: 0,
            width: '50vw',
            backgroundColor: 'white',
            zIndex: 0,
        },
        '&:before': {
            left: '100%',
        },
        '&:after': {
            right: '100%',
        },
        '& .install-column, & .fix-column': {
            backgroundColor: theme.palette.primary.main,
            padding: 32,
            marginBottom: 24,
            [theme.breakpoints.up('md')]: {
                flex: '0 0 50%',
                maxWidth: '50%',
                padding: '80px 80px 0',
            },
            '& h3': {
                color: theme.palette.primary.contrastText,
                fontSize: 28,
                lineHeight: 1.15,
                marginTop: 0,
                marginBottom: 24,
                [theme.breakpoints.up('md')]: {
                    fontSize: 32,
                }
            },
            '& p': {
                color: theme.palette.primary.contrastText,
                fontSize: 16,
                letterSpacing: '0.032em',
                lineHeight: 1.5,
                marginBottom: 32,
                marginTop: 0,
            },
            '& .links': {
                maxWidth: 110,
                marginBottom: 32,
                position: 'relative',
                zIndex: 10,
                [theme.breakpoints.up('md')]: {
                    height: 130,
                },
                '& a, & button': {
                    width: '100%',
                    border: 'none',
                    outline: 'none',
                    backgroundColor: 'transparent',
                    padding: 0,
                    textAlign: 'left',
                    display: 'block',
                    marginBottom: 16,
                    color: theme.palette.primary.contrastText,
                    borderBottom: '2px solid ' + theme.palette.primary.contrastText,
                    fontFamily: theme.bodyFont,
                    fontWeight: 700,
                    fontSize: 16,
                    lineHeight: 1.5,
                    letterSpacign: '0.008em',
                    textDecoration: 'none',
                }
            },
            '& .image-link': {
                display: 'block',
                width: 'calc(100% + 64px)',
                maxWidth: 'calc(100% + 64px)',
                margin: '0 -32px -32px !important',
                position: 'relative',
                [theme.breakpoints.up('md')]: {
                    width: 'calc(100% + 160px)',
                    maxWidth: 'calc(100% + 160px)',
                    margin: '-160px -80px 0 !important',
                },
                '& .gatsby-image-wrapper': {
                    [theme.breakpoints.up('md')]: {
                        width: '50%',
                        margin: '0 0 0 auto',
                    },
                    '& > div': {
                        paddingBottom: '69% !important',
                        [theme.breakpoints.up('md')]: {
                            paddingBottom: '410px !important',
                        },
                    },
                    '& img': {
                        objectPosition: 'top center !important',
                    }
                },
                '& span': {
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 75,
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    textDecoration: 'none',
                    fontSize: 18,
                    lineHeight: 1.5,
                    padding: '24px 26px',
                    [theme.breakpoints.up('md')]: {
                        left: 54,
                        right: '50%',
                        bottom: 50,
                    }
                },
                '&::after': {
                    content: '""',
                    width: 75,
                    height: 75,
                    display: 'block',
                    backgroundImage: 'url(' + arrowWhite + ')',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    [theme.breakpoints.up('md')]: {
                        right: 'auto',
                        left: '50%',
                        backgroundColor: theme.palette.primary.main,
                        bottom: 50,
                    }
                }
            }
        },
        '& div.fix-column': {
            backgroundColor: theme.palette.primary.contrastText,
            '& h3': {
                color: theme.palette.primary.main,
            },
            '& p': {
                color: theme.palette.primary.main,
            },
            '& .links': {
                '& a, & button': {
                    color: theme.palette.primary.main,
                    borderBottom: '2px solid ' + theme.palette.primary.main,
                }
            },
            '& .image-link': {
                '& span': {
                    backgroundColor: theme.palette.primary.contrastText,
                    color: theme.palette.primary.main,
                },
                '&::after': {
                    [theme.breakpoints.up('md')]: {
                        backgroundImage: 'url(' + arrowBlue + ')',
                        backgroundColor: theme.palette.primary.contrastText,
                    }
                }
            }
        },
        '& .video-overlay': {
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 100,
            padding: 32,
            display: 'none',
            backgroundColor: theme.palette.primary.main + '80',
            '&[open]': {
                display: 'block',
            },
            [theme.breakpoints.up('md')]: {
                padding: 80,
            },
            '& .content': {
                maxWidth: 1000,
                margin: '0 auto',
                position: 'relative',
                '& button': {
                    backgroundColor: theme.palette.primary.main,
                    width: 54,
                    height: 54,
                    borderRadius: '50%',
                    backgroundImage: 'url(' + close + ')',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    fontSize: 0,
                    position: 'absolute',
                    top: -16,
                    right: -16,
                    border: 'none',
                    zIndex: 10,
                    outline: 'none',
                    [theme.breakpoints.up(1160)]: {
                        top: 0,
                        right: -80,
                    }
                },
                '& .video-wrapper': {
                    paddingBottom: '56% !important',
                    width: '100%',
                    position: 'relative',
                    '& iframe': {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100% !important',
                        height: '100% !important',
                    },
                },
            },
        }
    },
    productFixTheme: {
        ...theme.productFixTheme,
    }
});