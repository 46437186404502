import buttonArrow from "../../../images/button-arrow-white.png";
import wishlist from "../../../images/basket-regular.png";
import wishlistFilled from "../../../images/basket-filled.png";
import close from "../../../images/close-icon.png";

export default (theme) => ({
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    width: "100%",
    height: "100%",
    overflowY: "overlay",
    overflowX: "hidden",
    display: "none",
    alignItems: "flex-start",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      alignItems: "center",
    },
    "& a.image-link": {
      "&:after": {
        backgroundImage: "url(" + buttonArrow + ")",
      },
    },
    "&.opened": {
      display: "flex",
    },
    "& section": {
      width: "100%",
      maxWidth: 1280,
      position: "relative",
      padding: "32px 0",
      "& button.close": {
        backgroundColor: theme.palette.secondary.main,
        width: 54,
        height: 54,
        borderRadius: "50%",
        backgroundImage: "url(" + close + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        fontSize: 0,
        position: "absolute",
        top: 16,
        right: 16,
        border: "none",
        zIndex: 10,
        outline: "none",
      },
    },
    "& .links": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      flexWrap: "wrap",
      "& button, & a": {
        borderRadius: 26,
        fontFamily: theme.bodyFont,
        fontSize: 18,
        letterSpacing: "0.008em",
        lineHeight: 2,
        fontWeight: 700,
        border: "none",
        padding: "10px 24px",
        margin: 12,
        position: "relative",
        display: "table",
        textDecoration: "none",
        "&::after": {
          content: '""',
          display: "inline-block",
          marginLeft: 12,
          width: 22,
          height: 20,
          verticalAlign: "text-top",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        },
        "&.wishlist": {
          backgroundColor: theme.palette.primary.contrastText,
          color: theme.palette.primary.main,
          boxShadow: "0 24px 26px rgba(0,0,0,0.16)",
          "&::after": {
            backgroundImage: "url(" + wishlist + ")",
          },
          "&.added": {
            "&::after": {
              backgroundImage: "url(" + wishlistFilled + ")",
            },
          },
          "& span": {
            position: "absolute",
            color: "white",
            fontSize: 8,
            lineHeight: 1,
            padding: 2,
            backgroundColor: "#E2004F",
            borderRadius: "50%",
            border: "1px solid white",
            width: 14,
            height: 14,
            textAlign: "center",
            top: 14,
            right: 22,
          },
        },
        "&.contact": {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
          "&::after": {
            backgroundImage: "url(" + buttonArrow + ")",
          },
        },
      },
    },
  },
});
