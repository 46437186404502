import React from "react";
import { makeStyles } from "@material-ui/styles";
import styles from "./styles";

import ProductsGrid from "../../Products/Components/ProductsGrid.js";

import { CoreHeadingBlock } from "../../blocks/CoreHeadingBlock";

const useStyles = makeStyles((theme) => styles(theme));

export default function ProductRelatedProducts(props) {
  const classes = useStyles();

  //   console.log(props);

  let products = props.products.map((product) => product.product);

  return (
    <div className={`also-need  ${classes.productRelatedProducts}`}>
      <CoreHeadingBlock
        attributes={{
          align: "",
          anchor: "",
          className: "",
          content: "Related Products",
          level: 4,
          backgroundColor: "",
          textColor: "",
          __typename: "WpCoreHeadingBlockAttributes",
        }}
        innerBlocks={[]}
      />
      <ProductsGrid products={products} />
    </div>
  );
}
