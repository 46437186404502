import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import Img from "gatsby-image";
import ProductViewer from "../ProductIntro/ProductViewer";

export default function ProductGallery(props) {
  // state for the slider
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const sliderRef = useRef(null);
  const navSliderRef = useRef(null);

  const settings = {
    fade: true,
    arrows: false,
    dots: false,
    asNavFor: nav2,
  };

  const navSettings = {
    asNavFor: nav1,
    slidesToShow: 4,
    slidesToScroll: 1,
    focusOnSelect: true,
    dots: false,
    arrows: true,
    infinate: false,
  };

  // remount slider on init
  useEffect(() => {
    setNav1(sliderRef.current);
    setNav2(navSliderRef.current);
  }, []);

  const productImages = props?.product?.product?.productGallery;
  const { images360 } = props?.product?.product;

  // combine slides and optionImages but only include first image from each option
  const slides = productImages ? productImages.filter((slide) => slide) : null;

  // console.log(slides);

  return (
    <>
      {slides ? (
        <div className="product-gallery">
          <div
            className="product-gallery-inner"
            style={{
              "--slick-slider-maxheight": `${
                slides.length > 4
                  ? `calc(60px + 112px * ${slides.length})`
                  : "560px"
              }`,
            }}
          >
            <Slider ref={sliderRef} className="image-slider" {...settings}>
              {images360 && (
                <div>
                  <div className="">
                    <ProductViewer images={images360} />
                  </div>
                </div>
              )}
              {slides.map(({ id, localFile }) => (
                <div key={id}>
                  <div className="">
                    <Img fluid={localFile.childImageSharp.fluid} />
                  </div>
                </div>
              ))}
            </Slider>
            <Slider ref={navSliderRef} {...navSettings} className="slider-nav">
              {images360 && (
                <div>
                  <span className="i360">360</span>
                  <img src={images360[0]?.localFile?.publicURL} alt="360" />
                </div>
              )}

              {slides.map(({ id, localFile }) => (
                <Img
                  key={id}
                  fluid={localFile.childImageSharp.fluid}
                  style={{
                    width: 134,
                    height: 134,
                    aspectRatio: 1,
                  }}
                />
              ))}
            </Slider>
          </div>
        </div>
      ) : null}
    </>
  );
}
