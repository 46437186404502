import React from 'react';
import { makeStyles } from '@material-ui/styles';
import ReactHtmlParser from "react-html-parser";
import styles from './styles';
import ProductGallery from '../ProductGallery';
import Img from "gatsby-image";
import ProductVariants from '../ProductVariants';
import SocialShare from '../../SocialShare';

const useStyles = makeStyles(theme => styles(theme));

function Logo(props) {
    if (!props.brands) return null;
    if (!props.brands.nodes) return null;
    if (!props.brands.nodes[0]) return null;
    if (!props.brands.nodes[0].BrandExtras.backgroundTexture && !props.brands.nodes[0].BrandExtras.logo) return null;

    if (!props.brands.nodes[0].BrandExtras.backgroundTexture) {
        return <div className="texture" style={{backgroundColor: props.brands.nodes[0].BrandExtras.backgroundColor}}>
            <Img className="brand-logo" fluid={props.brands.nodes[0].BrandExtras.logo.localFile.childImageSharp.fluid} />
        </div>;
    }

    if (!props.brands.nodes[0].BrandExtras.logo) {
        return <div className="texture" style={{backgroundColor: props.brands.nodes[0].BrandExtras.backgroundColor, backgroundImage: 'url(' + props.brands.nodes[0].BrandExtras.backgroundTexture.localFile.publicURL}} />;
    };

    return <div className="texture" style={{backgroundColor: props.brands.nodes[0].BrandExtras.backgroundColor, backgroundImage: 'url(' + props.brands.nodes[0].BrandExtras.backgroundTexture.localFile.publicURL}}>
        <Img className="brand-logo" fluid={props.brands.nodes[0].BrandExtras.logo.localFile.childImageSharp.fluid} />
    </div>;
}

export default function ProductIntro(props) {
    const classes = useStyles();
    const { id, title, brands, gallery, accreditations } = props.product;
    const { code, shortDescription, productGallery, variations, buyOffline, buyOnline } = props.product.product;
    return (
        <div className={`product-intro  ${classes.productIntro}  ${classes.productIntroTheme}`}>
            <div className="product-data">
                <Logo brands={brands} />
                <h1>{title}</h1>
                <p>{ReactHtmlParser(shortDescription)}</p>
                {variations === null &&
                    <p className="code"><span>Code</span>{code}</p>
                }
                <div className="desktop-prod-variants">
                    <ProductVariants code={String(code)} productId={id} variations={variations} preselect={props.location.state && props.location.state.query} buyOffline={buyOffline} buyOnline={buyOnline} />
                </div>
                <SocialShare
                    path={props.pageContext.pagePath}
                    title={title}
                />
            </div>
            <div className="product-accreditations">
                {accreditations.nodes.map((image) => {
                    if(image.AccreditationLogo.logo !== null) {
                        return <div className="accred-logo" key={image.AccreditationLogo.logo.id}>
                            <Img fluid={image.AccreditationLogo.logo.localFile.childImageSharp.fluid} />
                        </div>
                    }
                })}
            </div>

            {productGallery !== null &&
                <ProductGallery product={props.product} images={productGallery} />
            }
            {/* <div className="mobile-prod-variants">
                <ProductVariants productId={props.id} variations={props.variations} />
            </div> */}
        </div>
    )
}
