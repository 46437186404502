export default theme => ({
    productAlsoNeed: {
        position: 'relative',
        paddingBottom: 50,
        boxShadow: '-320px 0 0 white, 320px 0 0 white',
        backgroundColor: 'white',
        [theme.breakpoints.up('md')]: {
            paddingBottom: 100,
            boxShadow: '-500px 0 0 white, 500px 0 0 white',
        },
        '& h4': {
            marginBottom: 32,
        },
        '& .MuiGrid-grid-lg-4': {
            [theme.breakpoints.up('lg')]: {
                flex: '0 0 25% !important',
                maxWidth: '25% !important',
            }
        },
    },
});