import React, { Component } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import scrollTo from "gatsby-plugin-smoothscroll";

import ProductIntro from "../components/Product/ProductIntro";
import ProductOverview from "../components/Product/ProductOverview";
import ProductAlsoNeed from "../components/Product/ProductAlsoNeed";
import ProductFix from "../components/Product/ProductFix";
import ProductRelatedProducts from "../components/Product/ProductRelatedProducts";
import ProductOverlay from "../components/Product/ProductOverlay";
import Breadcrumb from "../components/Breadcrumb";

class Product extends Component {
  componentDidMount() {
    this.props.location.state &&
      this.props.location.state.scroll &&
      setTimeout(() => scrollTo(`.product-faqs`), 100);
  }
  render() {
    let catStack = [];

    if (this.props.data.wpProduct.productCategories !== null) {
      if (this.props.data.wpProduct.productCategories.nodes.length === 1) {
        catStack.push(this.props.data.wpProduct.productCategories.nodes[0]);
      } else {
        this.props.data.wpProduct.productCategories.nodes.forEach(
          (category) => {
            if (category.ancestors !== null) {
              if (category.ancestors.nodes.length >= catStack.length) {
                catStack = [
                  { id: category.id, name: category.name, slug: category.slug },
                ];
                category.ancestors.nodes.forEach((ancestor) => {
                  catStack.push(ancestor);
                });
              }
            }
          }
        );
      }
    }

    if (catStack.length > 1) {
      catStack.reverse();
    }

    // console.log(catStack);

    const mayAlsoNeed =
      this.props.data.wpProduct.product.mayAlsoNeed &&
      this.props.data.wpProduct.product.mayAlsoNeed
        .filter((product) => product)
        .filter((product) => {
          if (!product.product) return false;
          if (!product.product.status === "publish") return false;
          return product;
        });

    const relatedProducts =
      this.props.data.wpProduct.product.relatedProducts &&
      this.props.data.wpProduct.product.relatedProducts
        .filter((product) => product)
        .filter((product) => product.product)
        .filter((product) => {
          if (!product.product) return false;
          if (!product.product.status === "publish") return false;
          return product;
        });

    return (
      <Layout
        meta={{
          ...this.props.data.wpProduct.Meta,
          canonical: this.props.data.wpProduct.uri,
        }}
        path={this.props.pageContext.pagePath}
        cta={this.props.data.wpProduct.PageCTA}
        themeOptions={this.props.data.wp.themeOptions}
      >
        <Breadcrumb
          type="product"
          current={{
            title: this.props.data.wpProduct.title,
            slug: this.props.data.wpProduct.slug,
            uri: this.props.data.wpProduct.uri,
          }}
          ancestors={catStack}
        />
        <ProductIntro
          product={this.props.data.wpProduct}
          pageContext={this.props.pageContext}
          location={this.props.location}
        />
        <ProductOverview
          overview={this.props.data.wpProduct.product.overview}
          icons={this.props.data.wpProduct.product.overviewIcons}
          gallery={this.props.data.wpProduct.product.overviewGallery}
          path={this.props.pageContext.pagePath}
          faqs={this.props.data.wpProduct.product.faqs}
          datasheet={this.props.data.wpProduct.product.datasheetFile}
          brochure={this.props.data.wp.themeOptions.ThemeOptions.brochure}
          specificationBrochure={this.props.data.wp.themeOptions.ThemeOptions.specificationBrochure}
          bmi={this.props.data.wpProduct.product.bmiFile}
          instruction={this.props.data.wpProduct.product.instructionFile}
          features={this.props.data.wpProduct.product.features}
          specBlurb={this.props.data.wpProduct.product.specBlurb}
          specVideo={this.props.data.wpProduct.product.specVideo}
          specVideoFallback={this.props.data.wpProduct.product.specVideoFallback}
          specGallery={this.props.data.wpProduct.product.specGallery}
          specGallery2={this.props.data.wpProduct.product.specGallery2}
          buyOffline={this.props.data.wpProduct.product.buyOffline}
          buyOnline={this.props.data.wpProduct.product.buyOnline}
        />
        {mayAlsoNeed?.length > 0 && <ProductAlsoNeed products={mayAlsoNeed} />}
        {this.props.data.wpProduct.product.hasHowtoGuide === true &&
          <ProductFix
            install={{
              description: this.props.data.wpProduct.product.installDescription,
              video: this.props.data.wpProduct.product.installVideo,
              guide: this.props.data.wpProduct.product.installGuide,
            }}
            fix={{
              description: this.props.data.wpProduct.product.fixDescription,
              video: this.props.data.wpProduct.product.fixVideo,
              guide: this.props.data.wpProduct.product.fixGuide,
            }}
            slug={this.props.data.wpProduct.slug}
          />
        }
        {relatedProducts?.length > 0 && <ProductRelatedProducts products={relatedProducts} />}
        <ProductOverlay code={this.props.data.wpProduct.product.code} uri={this.props.data.wpProduct.uri} />
      </Layout>
    )
  }
}

export const query = graphql`
  query($id: String) {
    wpProduct(id: { eq: $id }, status: { eq: "publish" }) {
      id
      slug
      title
      uri
      featuredImage {
        node {
          sourceUrl
        }
      }
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      product {
        buyOffline
        buyOnline
        productGallery {
          id
          localFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 1660) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        shortDescription
        code
        overview
        overviewIcons {
          icon {
            id
            localFile {
              childImageSharp {
                fluid(quality: 80, maxWidth: 1660) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          text
        }
        overviewGallery {
          id
          localFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 1660) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        variations {
          variantName
          variantCode
          option1Name
          option1Value
          option2Name
          option2Value
          option3Name
          option3Value
          option4Name
          option4Value
        }
        hasHowtoGuide
        installDescription
        installVideo
        installGuide
        fixDescription
        fixVideo
        fixGuide

        datasheetFile {
          localFile {
            publicURL
          }
        }

        instructionFile {
          localFile {
            publicURL
          }
        }

        bmiFile {
          localFile {
            publicURL
          }
        }

        features {
          feature
        }

        faqs {
          question
          answer
        }

        specBlurb
        specVideo
        specVideoFallback {
          id
          localFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 1660) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        specGallery {
          id
          localFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 1660) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        specGallery2 {
          id
          localFile {
            childImageSharp {
              fluid(quality: 80, maxWidth: 1660) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }

        mayAlsoNeed {
          product {
            ... on WpProduct {
              id
              title
              uri
              link
              status
              featuredImage {
                node {
                  sourceUrl
                }
              }
              product {
                buyOffline
                buyOnline
                code
                description
                fieldGroupName
                fixDescription
                fixGuide
                fixVideo
                hasHowtoGuide
                installDescription
                installGuide
                installVideo
                introduction
                overview
                shortDescription
              }
              brands {
                nodes {
                  BrandExtras {
                    logo {
                      localFile {
                        childImageSharp {
                          fluid(quality: 80, maxWidth: 1660) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        relatedProducts {
          product {
            ... on WpProduct {
              id
              title
              uri
              link
              status
              featuredImage {
                node {
                  sourceUrl
                }
              }
              product {
                buyOffline
                buyOnline
                code
                description
                fieldGroupName
                fixDescription
                fixGuide
                fixVideo
                hasHowtoGuide
                installDescription
                installGuide
                installVideo
                introduction
                overview
                shortDescription
              }
              brands {
                nodes {
                  BrandExtras {
                    logo {
                      localFile {
                        childImageSharp {
                          fluid(quality: 80, maxWidth: 1660) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        images360 {
          id
          localFile {
            publicURL
            childImageSharp {
              fluid(quality: 80, maxWidth: 1660) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      productCategories {
        nodes {
          id
          name
          slug
          uri
          ancestors {
            nodes {
              id
              name
              slug
              uri
            }
          }
        }
      }
      accreditations {
        nodes {
          AccreditationLogo {
            logo {
              id
              localFile {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1660) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      brands {
        nodes {
          BrandExtras {
            logo {
              localFile {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1660) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            backgroundColor
            backgroundTexture {
              localFile {
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1660) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
                publicURL
              }
            }
          }
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
          brochure {
            localFile {
              publicURL
            }
            title
            sourceUrl
            id
          }
          specificationBrochure
        }
      }
    }
  }
`;

export default Product;
