import React from 'react';
import Img from "gatsby-image";

export default function ProductOverviewIcons(props) {
    if (!props.icons) return null;

    return (
        <div className="product-overview-icons">
            {props.icons.map(icon => {
                return <div className="icon-line" key={icon.icon.id}>
                    <span className="icon">
                        <Img fluid={icon.icon.localFile.childImageSharp.fluid} />
                    </span>
                    <span className="text">
                        {icon.text}
                    </span>
                </div>
            })}
        </div>
    )
}