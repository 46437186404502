import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from '@material-ui/styles';
import Link from '../../Link';
import styles from './styles';
import Img from "gatsby-image";

const useStyles = makeStyles(theme => styles(theme));

export default function ProductFix(props) {
    const [videoInstallOpen, setVideoInstallOpen] = React.useState(false);
    const [videoFixOpen, setVideoFixOpen] = React.useState(false);
    
    const handleVideoInstallOpen = () => {
        setVideoInstallOpen(!videoInstallOpen);
    }
    
    const handleVideoFixOpen = () => {
        setVideoFixOpen(!videoFixOpen);
    }


    const classes = useStyles();

    const data = useStaticQuery(graphql`
    query FixImages {
      wp {
        themeOptions {
          ThemeOptions {
            defaultImages {
                defaultFixImage {
                    localFile {
                        childImageSharp {
                            fluid(quality: 80, maxWidth: 1660) {
                            ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
                defaultInstallImage {
                    localFile {
                        childImageSharp {
                          fluid(quality: 80, maxWidth: 1660) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                }
            }
          }
        }
      }
    }
  `);

    return (
        <div className={`product-fix  ${classes.productFix}  ${classes.productFixTheme}`}>
            <div className="install-column">
                <h3>How to install</h3>
                <p>Explore thousands of kitchen plumbing supplies for a seamless installation or quick and easy fix.</p>
                <div className="links">
                    {props.install.video != null &&
                        <button onClick={handleVideoInstallOpen} onKeyDown={handleVideoInstallOpen}>View video</button>
                    }
                    {props.install.guide != null &&
                        <Link to={props.install.guide}>Video PDF</Link>
                    }
                    <Link to="/about-us/contact-us/">Contact Team</Link>
                </div>
                <Link to={"/expertise/how-to/" + props.slug + '/#install'} className="image-link">
                    <Img fluid={data.wp.themeOptions.ThemeOptions.defaultImages.defaultInstallImage.localFile.childImageSharp.fluid} />
                    <span>View install guides</span>
                </Link>
            </div>
            <div className="fix-column">
                <h3>How to maintain</h3>
                <p>Search our extensive range of bathroom plumbing supplies to complete the job with ease.  </p>
                <div className="links">
                    {props.fix.video != null &&
                        <button onClick={handleVideoFixOpen} onKeyDown={handleVideoFixOpen}>View video</button>
                    }
                    {props.fix.guide != null &&
                        <Link to={props.fix.guide}>Video PDF</Link>
                    }
                    <Link to="/about-us/contact-us/">Contact Team</Link>
                </div>
                <Link to={"/expertise/how-to/" + props.slug + '/#fix'} className="image-link">
                    <Img fluid={data.wp.themeOptions.ThemeOptions.defaultImages.defaultFixImage.localFile.childImageSharp.fluid} />
                    <span>View help guides</span>
                </Link>
            </div>
            {videoInstallOpen && props.install.video != null &&
                <div className="video-overlay install" open={videoInstallOpen}>
                    <div className="content">
                        <button onClick={handleVideoInstallOpen} onKeyDown={handleVideoInstallOpen}>Close</button>
                        <div className="video-wrapper">
                            <iframe title={"video-banner-"+props.install.video.replace("https://www.youtube.com/watch?v=", "")}
                                src={"https://www.youtube.com/embed/"+props.install.video.replace("https://www.youtube.com/watch?v=", "")}
                                frameBorder="0"
                                allowFullScreen
                            />
                        </div>
                    </div>
                </div>
            }
            {videoFixOpen && props.fix.video != null &&
                <div className="video-overlay fix" open={videoFixOpen}>
                    <div className="content">
                        <button onClick={handleVideoFixOpen} onKeyDown={handleVideoFixOpen}>Close</button>
                        <div className="video-wrapper">
                            <iframe title={"video-banner-"+props.fix.video.replace("https://www.youtube.com/watch?v=", "")}
                                src={"https://www.youtube.com/embed/"+props.fix.video.replace("https://www.youtube.com/watch?v=", "")}
                                frameBorder="0"
                                allowFullScreen
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}