export default (theme) => ({
  productViewer: {
    position: "relative",
    width: "100%",
    aspectRatio: "1 / 1",

    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "500px",
      aspectRatio: "unset",
    },

    "& .images": {
      position: "relative",
      width: "100%",
      height: "100%",
      overflow: "hidden",

      "& .image": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        opacity: 0,
        objectFit: "contain",
      },
    },
  },
});
