import { useEffect } from "react";
import useRafState from "react-use/lib/useRafState";
import { off, on } from "react-use/lib/misc/util";

const useTouch = (ref) => {
  if (process.env.NODE_ENV === "development") {
    if (typeof ref !== "object" || typeof ref.current === "undefined") {
      console.error("useTouch expects a single ref argument.");
    }
  }

  const [state, setState] = useRafState({
    docX: 0,
    docY: 0,
    posX: 0,
    posY: 0,
    elX: 0,
    elY: 0,
    elH: 0,
    elW: 0,
  });

  useEffect(() => {
    const touchHandler = (event) => {
      if (ref?.current && event.touches.length > 0) {
        const {
          left,
          top,
          width: elW,
          height: elH,
        } = ref.current.getBoundingClientRect();
        const posX = left + window.pageXOffset;
        const posY = top + window.pageYOffset;
        const elX = event.touches[0].pageX - posX;
        const elY = event.touches[0].pageY - posY;

        // check if touch is within element
        if (elX >= 0 && elX <= elW && elY >= 0 && elY <= elH) {
          setState({
            docX: event.touches[0].pageX,
            docY: event.touches[0].pageY,
            posX,
            posY,
            elX,
            elY,
            elH,
            elW,
          });
        }
      }
    };

    on(document, "touchmove", touchHandler);

    return () => {
      off(document, "touchmove", touchHandler);
    };
  }, [ref]);

  return state;
};

export default useTouch;
