import React, { useEffect, useRef, useState } from "react";
import { useMouseHovered } from "react-use";
import { makeStyles } from "@material-ui/styles";
import styles from "./viewer.styles";
import useTouch from "../../../helpers/useTouch";

const useStyles = makeStyles((theme) => styles(theme));

export default function ProductViewer(props) {
  const [activeImageIndex, setActiveImageIndex] = useState(
    Math.round(props.images.length / 2) || 0
  );

  const classes = useStyles();
  const images = props?.images?.map((image) => {
    return image?.localFile?.publicURL;
  });

  const canvasRef = useRef(null);

  const { elX, elW } = useMouseHovered(canvasRef, {
    bound: false,
    whenHovered: true,
  });

  const { elX: tX, elW: elWT } = useTouch(canvasRef);

  useEffect(() => {
    if (elX) {
      const imageIndex = Math.round((elX / elW) * images.length);
      // stop image going under 1
      if (imageIndex <= 0) {
        setActiveImageIndex(0);
        return;
      }
      // stop image going over total images
      if (imageIndex >= images.length) {
        setActiveImageIndex(images.length - 1);
        return;
      }
      setActiveImageIndex(imageIndex);
    }
    if (tX) {
      const imageIndex = Math.round((tX / elWT) * images.length);
      // stop image going under 1
      if (imageIndex <= 0) {
        setActiveImageIndex(0);
        return;
      }
      // stop image going over total images
      if (imageIndex >= images.length) {
        setActiveImageIndex(images.length - 1);
        return;
      }
      setActiveImageIndex(imageIndex);
    }
  }, [elX, elW, tX, elWT]);

  if (images?.length === 0) {
    return null;
  }

  return (
    <div className={classes.productViewer}>
      <div ref={canvasRef} className={"images"} style={{ zIndex: 99999 }}>
        {images?.map((image, index) => {
          return (
            <img
              key={image}
              className={"image"}
              style={{
                opacity: activeImageIndex === index ? 1 : 0,
              }}
              src={image}
              loading={index === 0 ? "eager" : "lazy"}
              alt={`360 images ${index}`}
            />
          );
        })}
      </div>
    </div>
  );
}
