export default (theme) => ({
  productRelatedProducts: {
    position: "relative",
    padding: "50px 0",
    zIndex: 2,
    boxShadow: "-320px 0 0 #fdf5f8, 320px 0 0 #fdf5f8",
    backgroundColor: "#fdf5f8",
    [theme.breakpoints.up("md")]: {
      padding: "100px 0 72px",
      boxShadow: "-500px 0 0 #fdf5f8, 500px 0 0 #fdf5f8",
    },
    "& h4": {
      marginBottom: 32,
    },
    "& .MuiGrid-grid-lg-4": {
      [theme.breakpoints.up("lg")]: {
        flex: "0 0 25% !important",
        maxWidth: "25% !important",
      },
    },
  },
});
